<template>
    <v-stepper v-model="stepper" outlined flat class="box-shadow  white">
        <v-stepper-header>
            <template v-for="step in steps">
                <v-stepper-step
                    :key="`${step}-step`"
                    :complete="stepper > step"
                    :step="step"
                    complete-icon="fa-solid fa-check"
                    :editable="stepper == step"
                    edit-icon="fa-solid fa-pencil"
                >
                    {{ $t(`builder.components.step`) }} {{ step }}
                </v-stepper-step>

                <v-divider v-if="step !== steps" :key="step"></v-divider>
            </template>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content
                v-for="step in steps"
                :key="`${step}-content`"
                :step="step"
            >
                <slot name="stepName"></slot>
                <slot name="stepMedia"></slot>
                <slot name="stepDescription"></slot>
                <slot name="action"></slot>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
export default {
    name: "Stepper",

    props: {
        getStep: {
            required: true,
            type: Number,
            default: () => {
                return 1;
            },
        },
        getStepper: {
            required: true,
            type: Number,
            default: () => {
                return 1;
            },
        },
    },

    data() {
        return {
            stepper: 1,
            steps: 1,
        };
    },

    methods: {
        saveStep() {
            this.$emit("save-step");
        },
    },

    watch: {
        steps(val) {
            if (val) this.$emit("update:steps-value", this.steps);
        },

        getStep(val) {
            if (val) this.steps = val;
        },

        getStepper(val) {
            if (val) this.stepper = val;
        },
    },

    created() {
        this.$emit("update:steps-value", this.steps);
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
</style>
